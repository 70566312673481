import { Button } from "@mui/material";
import { Link } from "react-router-dom";
const Buttons = ({ adm, variant, variant1, variant2, variant3 }) => {
    return (
        <div>
            {adm}
            &nbsp;
            {adm ?
                <div>
                    <Link to="/admin">
                        <Button variant={variant} >
                            Admin
                        </Button>
                    </Link>
                    &nbsp;
                    <Link to="/">
                        <Button variant={variant1} >
                            Registrer skadefri dager
                        </Button>
                    </Link>
                    &nbsp;
                    <Link to="/fravarende">
                        <Button variant={variant2} >
                            Registrer dager siden sist fraværsskade
                        </Button>
                    </Link>
                    &nbsp;
                    <Link to="/main">
                        <Button variant={variant3} >
                            Visningsside
                        </Button>
                    </Link>
                </div>
                :
                <div>
                    <Link to="/">
                        <Button variant={variant1} >
                            Registrer skadefri dager
                        </Button>
                    </Link>
                    &nbsp;
                    <Link to="/fravarende">
                        <Button variant={variant2} >
                            Registrer dager siden sist fraværsskade
                        </Button>
                    </Link>
                    &nbsp;
                    <Link to="/main">
                        <Button variant={variant3} >
                            Visningsside
                        </Button>
                    </Link>
                </div>
            }

        </div>
    );
}

export default Buttons;