import { useEffect, useState } from "react";
import { getDocs, collection, updateDoc, doc } from "firebase/firestore";
import { db } from "../firebase.config/config";
import { Button } from "@mui/material";
import '../styles/Admin.css';

const Admin = () => {

    const [users, setUsers] = useState([]);

    async function getUsers() {
        const q = collection(db, "users");
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            if (doc.data().access === false && doc.data().isAdmin === false) {
                let ad = 1;
                if (users.length > 0) {
                    users.forEach(element => {
                        if (element.email === doc.data().email) {
                            ad = 0;
                        }
                    });
                }
                else {
                    setUsers([...users, doc.data()]);
                }
                if (ad === 1) {
                    setUsers([...users, doc.data()]);
                }

            }
        });
    }

    useEffect(() => {
        getUsers();
    }, [users]);

    async function Approve(email){
        const q = collection(db, "users");
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (item) => {
            if (item.data().access === false && item.data().isAdmin === false) {
                if (email === item.data().email) {
                    await updateDoc(doc(db, "users", item.id), {
                        access: true,
                    })
                }
            }
        });
        setUsers([]);
    }

    return (
        <div>
            <table>
                <thead>
                    <th>User email</th>
                    <th>Action</th>
                </thead>
                <tbody>
                    {
                        users.map(item => {
                            return (
                                <tr key={item.uid}>
                                    <td>
                                        {item.email}
                                    </td>
                                    <td>
                                        <Button variant="contained" onClick={() => Approve(item.email)}>Aprove</Button>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default Admin;
