import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

/*const firebaseConfig = {
  apiKey: "AIzaSyAEh-w9CCH3YT12K6gMj7R1T0qLRcq05M0",
  authDomain: "publy-skadefri-dager-f6834.firebaseapp.com",
  projectId: "publy-skadefri-dager-f6834",
  storageBucket: "publy-skadefri-dager-f6834.appspot.com",
  messagingSenderId: "79613385720",
  appId: "1:79613385720:web:658366ce717c031e1a540d",
  measurementId: "G-C1X2PNT385"
};*/

const firebaseConfig = {
  apiKey: "AIzaSyCpIc2p0WDo-Wduq_F1at5P10zM-j0PTJg",
  authDomain: "con-form-skadefri-dager.firebaseapp.com",
  projectId: "con-form-skadefri-dager",
  storageBucket: "con-form-skadefri-dager.appspot.com",
  messagingSenderId: "715027541700",
  appId: "1:715027541700:web:357bd2a802ba07c0d2e391"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore(app);