import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase.config/config";
import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { addDoc, collection } from "firebase/firestore";
import { db } from '../firebase.config/config'
import '../styles/LoginPage.css';
import { Link } from "react-router-dom";

export default function SignUpPage() {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const SignUp = async () => {
        let valid = 1;
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                console.log(userCredential);
            }).
            catch((error) => {
                console.log(error);
                valid = 0;
            })
        setTimeout(async () => {
            if (valid === 1) {
                await addDoc(collection(db, "users"), {
                    uid: auth.currentUser.uid,
                    access: false,
                    email: email,
                    isAdmin: false
                })
            }
        }, 2000);

    }
    return (
        <div className="main_container">
            <form onSubmit={SignUp}>
                <br />
                <br />
                <div style={{ fontSize: "35px" }}>
                    Opprett konto
                </div>
                <br />
                <br />
                <TextField id="outlined-basic" label="Epost" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                <br />
                <br />
                <br />
                <TextField id="outlined-basic" label="Passord" variant="outlined" type="password" onChange={(e) => setPassword(e.target.value)} />
                <br />
                <br />
                <div>
                    Har du en konto? &nbsp;
                    <Link to="/">
                        Logg inn
                    </Link>
                </div>
                <br />
                <br />
                <Link to="/">
                    <Button variant="contained" onClick={SignUp}>
                        Registrer
                    </Button>
                </Link>
            </form>
        </div>
    );
}
