import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase.config/config";
import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { db } from '../firebase.config/config'
import '../styles/LoginPage.css';
import { Link } from "react-router-dom";

export default function SignInPage({ setAccept }) {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const SignIn = (e) => {
    e.preventDefault();
    let valid = 1;
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log(userCredential);
      }).
      catch((error) => {
        console.log(error);
        valid = 0;
      })

    setTimeout(async () => {
      if (valid === 1) {
        const q = collection(db, "users");
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          if (doc.data().access === true && doc.data().email === email) {
            setAccept(true);
          }
        });
      }
    }, 1000);
  }
  return (
    <div className="main_container">
      <form onSubmit={SignIn}>
        <br />
        <br />
        <div style={{ fontSize: "35px" }}>
          Logg inn
        </div>
        <br />
        <br />
        <TextField id="outlined-basic" label="Epost" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
        <br />
        <br />
        <br />
        <TextField id="outlined-basic" label="Passord" variant="outlined" type="password" onChange={(e) => setPassword(e.target.value)} />
        <br />
        <br />
        <div>
          Mangler du konto? &nbsp;
          <Link to="/signUp">
            Opprett Konto
          </Link>
        </div>
        <br />
        <br />
        <Button type="submit" variant="contained">
          Logg inn
        </Button>
      </form>
    </div>
  );
}
