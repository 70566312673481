import SignInPage from './components/SignInPage'
import SignUpPage from './components/SignUpPage';
import './App.css';
import { useEffect, useState } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from './firebase.config/config';
import { Button } from "@mui/material";
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { getDocs, collection } from "firebase/firestore";
import { db } from './firebase.config/config';
import SkadeFri from './components/SkadeFri';
import FravarendeFri from './components/FravarendeFri';
import Buttons from './components/Buttons';
import MainPage from './components/MainPage';
import Api from './components/ApiComponent';
import Admin from './components/AdminComponent';

function App() {

  const [authuser, setUser] = useState(null);
  const [isAccept, setAccept] = useState(false);
  const [isAdmin, setAdmin] = useState(false);

  useEffect(() => {
    const listen = onAuthStateChanged(auth, async (user) => {
      setAccept(false);
      setAdmin(false);
      
      if (user) {
        const q = collection(db, "users");
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((item) => {
          if (user.uid === item.data().uid && item.data().access === true) {
            setAccept(true);
            if (item.data().isAdmin === true) {
              setAdmin(true);
            }
          }
        })
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => {
      listen();
    }
  }, []);

  function SignOut() {
    signOut(auth);
  }

  return (
    <BrowserRouter>
      <div className="App">
        {
          authuser ?
            isAccept ?
              <div>
                <Routes>
                  <Route path='/' element={
                    <div>
                      <Link to='/'>
                        <Button onClick={SignOut}>
                          Logg ut
                        </Button>
                      </Link>
                      <br />
                      <br />
                      <SkadeFri />
                      <br />
                      <br />
                      <br />
                      <Buttons adm={isAdmin} variant="contained" variant1="outlined" variant2="contained" variant3="contained" />
                    </div>
                  } />
                  <Route path='/fravarende' element={
                    <div>
                      <Link to='/'>
                        <Button onClick={SignOut}>
                        Logg ut
                        </Button>
                      </Link>
                      <br />
                      <br />
                      <FravarendeFri />
                      <br />
                      <br />
                      <br />
                      <Buttons adm={isAdmin} variant="contained" variant1="contained" variant2="outlined" variant3="contained" />
                    </div>
                  } />
                  <Route path='/main' element={
                    <div>
                      <MainPage />
                    </div>
                  } />
                  <Route path='/admin' element={
                    <div>
                      <Link to='/'>
                        <Button onClick={SignOut}>
                        Logg ut
                        </Button>
                      </Link>
                      <br />
                      <br />
                      <Admin />
                      <br />
                      <br />
                      <br />
                      <Buttons adm={isAdmin} variant="outlined" variant1="contained" variant2="contained" variant3="contained" />
                    </div>
                  } />
                </Routes>

                <br />
                <br />
                <br />

              </div> :
              <div>
                <Button onClick={SignOut}>
                Logg ut
                </Button>
                <h1>
                  Din bruker har ikke tilgang til denne siden
                </h1>
              </div>
            :
            <div>
              <Routes>
                <Route path='/' element={
                  <div>
                    <SignInPage setAccept={setAccept} />
                  </div>
                } />
                <Route path='/main' element={
                  <div>
                    <MainPage />
                  </div>
                } />
                <Route path='/signUp' element={
                  <div>
                    <SignUpPage />
                  </div>
                } />
                <Route path='/api' element={
                  <div>
                    <Api />
                  </div>
                } />
              </Routes>
            </div>
        }
      </div>
    </BrowserRouter>
  );
}

export default App;
