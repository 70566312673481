import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebase.config/config";
import {useState, useEffect} from "react"

const Api = () => {
    const [days, setDays] = useState('');
    const [days2, setDays2] = useState('');

    async function getDays() {
        const q = collection(db, "skadefri");
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            var firebaseDate = new Date(doc.data().date);
            var currentDate = new Date();

            let cdays = currentDate.getDate();
            let month = currentDate.getMonth();
            let year = currentDate.getFullYear();

            currentDate = new Date(year, month, cdays);
            const differenceInMilliseconds = currentDate - firebaseDate;
            const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

            setDays(differenceInDays);
        });

        const q2 = collection(db, "fravarende");
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) => {
            var firebaseDate = new Date(doc.data().date);
            var currentDate = new Date();

            let cdays = currentDate.getDate();
            let month = currentDate.getMonth();
            let year = currentDate.getFullYear();

            currentDate = new Date(year, month, cdays);
            const differenceInMilliseconds = currentDate - firebaseDate;
            const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            console.log(differenceInDays)
            setDays2(differenceInDays);
        });
    }
    useEffect(() => {
        getDays();
    }, [])

    if(!days){
        return;
    }else {
        return <code>{JSON.stringify({days, days2})}</code>

    }
}

export default Api;