import { useEffect, useState } from "react";
//import { getDocs, collection } from "firebase/firestore";
//import { db } from "../firebase.config/config";
import '../styles/MainPage.css'
import img from '../images/con-form-back.png';

const MainPage = () => {
    const [days, setDays] = useState('');
    const [days2, setDays2] = useState('');

    /*async function getDays() {
        const q = collection(db, "skadefri");
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            var firebaseDate = new Date(doc.data().date);
            var currentDate = new Date();

            let cdays = currentDate.getDate();
            let month = currentDate.getMonth();
            let year = currentDate.getFullYear();

            currentDate = new Date(year, month, cdays);
            const differenceInMilliseconds = currentDate - firebaseDate;
            const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

            setDays(differenceInDays)
        });

        const q2 = collection(db, "fravarende");
        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) => {
            var firebaseDate = new Date(doc.data().date);
            var currentDate = new Date();

            let cdays = currentDate.getDate();
            let month = currentDate.getMonth();
            let year = currentDate.getFullYear();

            currentDate = new Date(year, month, cdays);
            const differenceInMilliseconds = currentDate - firebaseDate;
            const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

            setDays2(differenceInDays);
        });
    }*/

    const getDays2 = () => {
        fetch('https://con-form.publy.no/api/data')
            .then(res => res.json())
            .then(res => {
                setDays(res.days);
                setDays2(res.days2);
            })
    }


    useEffect(() => {
        getDays2();
        // Fetch every 30 seconds
        const interval = setInterval(() => {
            getDays2();
        }, 30000);
        return () => clearInterval(interval);
        
    }, [])
    return (
        <div>
            <table>
                <tr>
                    <tr>
                        <td className="tdc2">
                            <div>
                                Skadefrie dager: <b>{days}</b>
                            </div>
                        </td>
                    </tr>
                    <tr class="center-row">
                        <td>
                            <div class="container">
                                <img src={img} alt="" width={'100%'} />
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="tdc">
                            <div>
                                Dager siden siste fraværsskade: <b>{days2}</b>
                            </div>
                        </td>
                    </tr>
                </tr>
            </table>

            <br />

        </div>
    );
}

export default MainPage;