import { useState } from "react";
import { addDoc, collection, doc, getDocs, updateDoc } from "firebase/firestore";
import { db } from '../firebase.config/config'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Alert, Button } from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import 'dayjs/locale/nb'
import { nbNO } from '@mui/x-date-pickers/locales';

const SkadeFri = () => {
    const [open, setOpen] = useState(false);

    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleClose = () => {
    
        setOpen(false);
      };

    async function addData() {
        var date = new Date(selectedDate)
        console.log(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate())
        const q = collection(db, "skadefri");
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size > 0) {
            querySnapshot.forEach(async (doc2) => {
                await updateDoc(doc(db, "skadefri", doc2.id), {
                    date: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
                });
            });
        }
        else {
            await addDoc(collection(db, "fravarende"), {
                date: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
            })
        }
        setOpen(true);
    }

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="nb" localeText={nbNO.components.MuiLocalizationProvider.defaultProps.localeText} >
                <DatePicker
                    label="Siste skadefrie dag"
                    value={selectedDate}
                    onChange={handleDateChange}
                />
            </LocalizationProvider>
            <br />
            <br />
            <Button variant="contained" onClick={addData}>Registrer</Button>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Dato oppdatert
                </Alert>
            </Snackbar>
        </div>

    );
}

export default SkadeFri;